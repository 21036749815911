import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Col, Row } from "react-bootstrap";
import CallToActionBanner from "../components/call-to-action-banner";
import Carousel3D from "../components/carousel-3d";
import ImgSection from "../components/img-section";
import RasterImg from "../components/raster-img";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`DamuNFT`}</h1>
    <br />
    <p>{`DamuNFT aims to teach collectors about the long forgotten, diverse nature and iconic meaning of African arts. The Genesis DamuNFT Collection is a collection of 3,000 unique Non-Fungible Tokens (NFTs) on the Ethereum blockchain to represent the roughly 3,000 tribes that inhabit the African continent.`}</p>
    <p>{`This is an opportunity to participate in the first collections of African Digital Masks.`}</p>
    <br />
    <h2>{`Fair Distribution Model`}</h2>
    <br />
    <ImgSection img="gif0-30p.gif" useImgTag mdxType="ImgSection">
      <p>{`The DamuNFT Genesis Collection will be available to mint for just 0.035 Ethereum – there will be no price tiers. The entire DamuNFT Genesis Collection will be sold and distributed through a random airdrop, via a verified Smart Contract that will be publicly shared on this website as well as in our Discord server and on our Twitter page.`}</p>
    </ImgSection>
    <br /><br />
    <CallToActionBanner url="https://opensea.io/collection/" mdxType="CallToActionBanner">
      <img src="mask-3-bullet.png" />
      <span>Purchase on OpenSea here</span>
      <img src="mask-3-bullet.png" />
    </CallToActionBanner>
    <br /><br />
    <h2>{`The DamuNFT Collection`}</h2>
    <Carousel3D images={['Mask-2-Preview.jpg', 'Mask-3-Preview.jpg', 'Mask-4-Preview.png', 'Mask-5-Preview.png', 'Mask-6-Preview.png', 'Mask-7-Preview.png', 'Mask-8-Preview.jpg', 'Mask-10-Preview.jpg']} mdxType="Carousel3D" />
    <p>{`This collection is inspired by the beliefs, cultures and histories of ceremonial masks across the continent of Africa. We want each NFT holder to have a share of knowledge in the history of the African continent. Each mask will be displayed in digital format, and minted as an NFT. The NFT allows the holder to vote, submit proposals and gain early access to future Damu projects.`}</p>
    <br />
    <div className="brown-highlight">
      <h2>{`Revealing the esoteric meaning of African Arts`}</h2>
      <br />
      <Row mdxType="Row">
        <Col className="col-12 d-flex flex-column justify-content-center" sm="2" mdxType="Col">
          <img className="mb-5 w-100" src="gif3-30p.gif" />
        </Col>
        <Col className="col-12 d-flex flex-column justify-content-center" sm="8" mdxType="Col">
          <p>{`DamuNFT combines digital art with the knowledge of NFT blockchain technology to educate the world about African masks, in particular. African masks are popular during social or ritual contexts. Before now, they served as a means of seeking prestige and protection during esoteric rituals. Historically, they’re used by shamans to mediate the spiritual world and visible reality during divination. But don’t worry, DamuNFT only tells you the history by showing you their digital form.`}</p>
        </Col>
        <Col className="col-12 d-flex flex-column justify-content-center" sm="2" mdxType="Col">
          <img className="mb-5 w-100" src="gif1-30p.gif" />
        </Col>
      </Row>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      