import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Col, Row } from "react-bootstrap";
import ImgSection from "../components/img-section";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Roadmap`}</h1>
    <br />
    <ImgSection imgListStyleUrl="mask-3-bullet.png" img="gif2-30p.gif" useImgTag mdxType="ImgSection">
      <ul>
        <li parentName="ul">
          <p parentName="li">{`The purchase of the DamuNFT is a pass for future NFT drops.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Holders of the DamuNFT will be able to claim $DAMU every 30 days for use for marketplace items.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`The marketplace will contain Damu metaverse items (Damu voxels, Damu 3D items, etc.)`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Future roadmap items will be proposed and voted on by the DamuNFT DAO participants`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`This project is set to go beyond the moon, and with Elon to Mars!!!!`}</p>
        </li>
      </ul>
    </ImgSection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      