import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`WHY DamuNFTs`}</h1>
    <div className="feature-set">
      <p>{`Complete ownership rights to personal and commercial use granted to the NFT holder`}</p>
      <p>{`Ownership of masks with greatness rich in history`}</p>
      <p>{`The NFT collection with traceable arts in real life`}</p>
      <p>{`Created by artists that are inspired by history.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      