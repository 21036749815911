import { Col, Row } from "react-bootstrap"
import React from "react"
import styled from "@emotion/styled"

const CallToActionBanner = ({ id, className, url=null, children }) => {


  let content =
    <StyledRow id={id} className={`align-items-center justify-content-center mx-auto w-100 ${className || ''}`}>
      <Col className="my-2 text-center" lg={8} md={6} sm={6} xs={10}>{children}</Col>
    </StyledRow>

  content = url ? <Wrapper href={url}>{content}</Wrapper> : content

  return (
    <>{content}</>
  )
}

const StyledRow = styled(Row)`
  background: #634945;
  border-radius: 12px;
  border: 5px solid #634945;
  padding: 0;
  outline-offset: 4px;
  text-transform: uppercase;

  &:hover {
    border: 5px solid #fff;
  }
  img {
    padding: 20px;
  }
`;

let Wrapper = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
`;

export default CallToActionBanner;
