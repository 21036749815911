import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import CardGrid from "../components/card-grid";
import ImgSection from "../components/img-section";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Project Team`}</h1>
    <br />
    <CardGrid cardsPerRow={4} textColor={"white"} mdxType="CardGrid">
      <ImgSection img="adeleke-img" contain imgHeight="175px" url="https://www.linkedin.com/in/adebayoadeleke/" smallImage imgTop mdxType="ImgSection">
        <p><strong parentName="p">{`Adebayo Adeleke`}</strong></p>
      </ImgSection>
      <ImgSection img="george-ashiru-img" contain imgHeight="175px" url="https://www.linkedin.com/in/sarah-george-ashiru/" smallImage imgTop mdxType="ImgSection">
        <p><strong parentName="p">{`Sarah George-Ashiru`}</strong></p>
      </ImgSection>
      <ImgSection img="uka-img" imgHeight="175px" contain url="https://www.linkedin.com/in/franklin-uka/" smallImage imgTop mdxType="ImgSection">
        <p><strong parentName="p">{`Franklin Uka`}</strong></p>
      </ImgSection>
      <ImgSection img="degan-img" imgHeight="175px" contain url="https://twitter.com/phd_justin" smallImage imgTop mdxType="ImgSection">
        <p><strong parentName="p">{`Dr. Degen, PhD`}</strong></p>
      </ImgSection>
    </CardGrid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      