import { Col, Row, Container } from "react-bootstrap"
import React from "react"
import styled from "@emotion/styled"

const CardGrid = ({ cardsPerRow, textColor = "white", children }) => {
  const mdSpan = Math.floor(12 / cardsPerRow)

  return (
    <Grid textColor={textColor}>
      { children.map((c) => { return <Col xs={5} md={mdSpan}>{c}</Col>}) }
    </Grid>
  )
}

const Grid = styled(Row)`
  align-items: flex-start;
  color: ${props => props.textColor };
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  @media (max-width: 600px) {
    width: 100%;
  }

  > * {
    border: 3px solid #634945;
    border-radius: 10px;
    /* flex-basis: 45%; */
    /* margin: 1%; */
    /* padding: 1.5rem; */
    text-align: left;
    text-decoration: none;
    transition: color 0.15s ease, border-color 0.15s ease;

    &:hover, &:focus, &:active {
      background-color: #634945;
      border-color: #634945;
    }

    a {
      color: ${props => props.textColor };
      text-decoration: none;

      &:hover, &:focus, &:active {
        color: #ffffff;
      }
    }

    h3 {
      margin: 0 0 1rem 0;
      font-size: 1.5rem;
    }

    p {
      font-size: 1.25rem;
      line-height: 1.5;
      text-align: center;
    }
  }
`;

export default CardGrid;
