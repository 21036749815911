import { Col, Row } from "react-bootstrap"
import React from "react"

import RasterImg from "./raster-img"

import styled from "@emotion/styled"

const ImgSection = ({ markdown, img, imgListStyleUrl="", contain = null, imgHeight="auto", imgTop = false, smallImage = false, url=null, useImgTag = false, children }) => {
  const Markdown = markdown;

  let Image = styled(RasterImg)`
    height: auto;
    width: 100%;
  `

  if (useImgTag) {
    Image = styled.img`
     height: auto;
     width: 100%;
   `
 }
 console.log(imgListStyleUrl)
 const WideSection = () => {return <>
    <Col lg={smallImage ? 6 : 6}>
      { Markdown && <Markdown />}
      { children }
    </Col>
    <Col lg={smallImage ? 3 : 5} className="d-flex flex-column justify-content-center offset-lg-1 p-3 mt-3 mt-md-0">
      <Image src={img} alt={img} contain={contain} height={imgHeight} />
    </Col>
  </>};

  const NarrowSection = () => { return <>
      <Col className="d-flex flex-column justify-content-center mt-3 mt-md-0 p-3">
        <Image src={img} alt={img} contain={contain} height={imgHeight} />
      </Col>
      <Col>
        { Markdown && <Markdown />}
        { children }
      </Col>
    </>};

  let Section = imgTop ? NarrowSection : WideSection
  let Wrapper = styled.a`
  `;

  let content =
    <StyledRow imgListStyleUrl={imgListStyleUrl} imgTop={imgTop} className={`justify-content-center m-0 p-0 sub-block`} >
      <Section />
    </StyledRow>

  content = url ? <Wrapper href={url}>{content}</Wrapper> : content

  return (
      <>
      {content}
      </>
  )
}

const StyledRow = styled(Row)`
  flex-direction: ${props => props.imgTop ? "column" : "row"};

  ul {
    list-style-type:none;

    li {
      position:relative;

      &:before {
        content:${props => props.imgListStyleUrl ? `url('${props.imgListStyleUrl}')` : "none" };
        left: -45px;
        padding:0 10px 0 0;
        position:absolute;
        top: 2px;
      }
    }
  }
`;

export default ImgSection;
